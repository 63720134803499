import React, { useState, useEffect, useRef } from "react";

import moment from "moment";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { CatererListFilterDrawer } from "./molecules/caterer-list-filter-drawer";
import { useQuery, useReactiveVar } from "@apollo/client";
import { Pagination, SelectChangeEvent, Stack } from "@mui/material";
import LoginHeader from "../../Modules/Login/LoginHeader";
import { CatererFilter } from "../../components/organisms/caterer-filter";
import { LIST_CUISINE_TYPE, LIST_CATERER_SEARCH } from "../../Graphql/queries";

import { convertTimeFormat } from "../../Modules/Common/commonUtils";
import { retainFilter } from "../../ReactiveVariables";
import { useAppDispatch } from "../../services/redux";
import { clearEditOrder } from "../../services/redux/order";
import { EmptyCatererListMessage } from "./atoms/empty-caterer-list-message";
import { CatererLoader } from "./atoms/caterer-loader";
import { CatererItem } from "./atoms/caterer-item";
import { CatererListFilter } from "./molecules/caterer-list-filter";
import { ISearchLocationPayload } from "@components/atoms/location-search";

export const PACKAGE_OPTIONS_LIST = [
  { id: "0", value: "Allows same day orders" },
  { id: "1", value: "Individually wrapped meals" },
  { id: "2", value: "Offers packages" },
];

const CATERER_LIST_PAGE_NUM = 15;

interface IHandleChangePageEvent {
  target: {
    value: string;
  };
}

export const CatererList = (props) => {
  const parentRef = useRef(null);
  const filterParameter: any = useReactiveVar(retainFilter);

  const [isLocationCorrect, setLocationCorrect] = useState(false);
  const [contactNames, setContactNames] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [locationNames, setLocationNames] = useState("");
  const [suite, setSuite] = useState("");
  const [instructions, setInstructions] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [addressIds, setAddressIds] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dietary, setDietary] = useState("Dietary");
  const [option, setOption] = useState(["options"]);
  const [headCount, setHeadCount] = useState(1);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [zipCode, setZipCode] = useState("");
  const [savedAddressId, setSavedAddressId] = useState("");
  const [cuisines, setCuisines] = useState(["All Cuisines"]);
  const [sortby, setSortby] = useState("CaterCash");
  const [openFilter, setOpenFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [isFilterUpdate, setFilterUpdate] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let deliveryDay = moment(startDate).format("dddd");
  let deliveryDate = moment(startDate).format("L");
  const { data: cuisinesData } = useQuery(LIST_CUISINE_TYPE, {
    variables: { sortField: "cuisineName" },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFilterUpdate(
      option.length > 1 || dietary !== "Dietary" || cuisines.length > 1 || searchKeyword.length > 1,
    );
  }, [searchKeyword, dietary, cuisines, option]);

  const caterlistVariabel = {
    skip: (page - 1) * CATERER_LIST_PAGE_NUM,
    limit: CATERER_LIST_PAGE_NUM,
    search: searchKeyword,
    location: coordinates,
    deliveryDateTime: startDate,
    deliveryDate: deliveryDate === "Invalid date" ? null : deliveryDate,
    deliveryTime: startDate === "Invalid date" ? null : convertTimeFormat(startDate),
    deliveryDay: deliveryDay === "Invalid date" ? null : deliveryDay,
    dietary: dietary === "Dietary" ? null : dietary,
    options: option.length === 1 ? null : option,
    cuisines: cuisines.length === 1 ? null : cuisines,
    sortOrder: sortby === "CaterCash" ? null : sortby,
    timeZone: timeZone,
  };

  const { data: catererData, loading: catererloading } = useQuery(LIST_CATERER_SEARCH, {
    skip: !isLocationCorrect,
    variables: caterlistVariabel,
  });

  useEffect(() => {
    setLocationCorrect(coordinates && coordinates.length && coordinates[0] && coordinates[1]);
  }, [coordinates]);

  const handleChangePage = (e: React.ChangeEvent<IHandleChangePageEvent>, pages: number) => {
    setPage(pages);

    navigate(
      {
        pathname: location.pathname,
        search: `?${new URLSearchParams({ ...Object.fromEntries(searchParams), page: pages.toString() })}`,
      },
      { state: location.state } // Preserve state when navigating
    );

    if (parentRef.current) {
      (parentRef.current as HTMLElement).scrollTop = 0;
    }
  };

  function onUpdateLocationOptions({ zip, coordinates, city }: ISearchLocationPayload) {
    setCoordinates(coordinates);
    setZipCode(zip);
    setCity(city);
  }

  useEffect(() => {
    const currentPage = Number(searchParams.get("page")) || 1;
    setPage(currentPage);
  }, [searchParams]);

  useEffect(() => {
    dispatch(clearEditOrder());

    if (data?.address) {
      setAddress(data?.address);
    }
    if (data?.city) {
      setCity(data?.city);
    }
    if (data?.addressId) {
      setAddressIds(data?.addressId);
    }
    if (data?.date) {
      setStartDate(data?.date);
    }
    if (data?.count) {
      setHeadCount(data?.count);
    }
    if (data?.cordinates) {
      setCoordinates(data?.cordinates);
    }
    if (data?.zipCode) {
      setZipCode(data?.zipCode);
    }
    if (data?.savedAddressId) {
      setSavedAddressId(data?.savedAddressId);
    }
    if (data?.suit) {
      setSuite(data?.suit);
    }
    if (data?.locationName) {
      setLocationNames(data?.locationName);
    }
    if (data?.instructions) {
      setInstructions(data?.instructions);
    }
    if (data?.contactPerson) {
      setContactNames(data?.contactPerson);
    }
    if (data?.contactNo) {
      setContactNo(data?.contactNo);
    }
    if (filterParameter?.search) {
      setSearchKeyword(filterParameter?.search);
    }
    if (filterParameter?.dietary) {
      setDietary(filterParameter?.dietary);
    }
    if (filterParameter?.sortby) {
      setSortby(filterParameter?.sortby);
    }
    if (filterParameter?.cuisine) {
      setCuisines(filterParameter?.cuisine);
    }
    if (filterParameter?.option) {
      setOption(filterParameter?.option);
    }
  }, []);

  const handleClear = () => {
    setSearchKeyword("");
    setDietary("Dietary");
    setOption(["options"]);
    setCuisines(["All Cuisines"]);
    setSortby("CaterCash");
  };

  let urlData = {
    address: address,
    date: startDate,
    count: headCount,
    cordinates: coordinates,
    zipCode: zipCode,
    savedAddressId: savedAddressId,
    city: city,
    addressId: addressIds,
    suit: suite,
    instructions: instructions,
    locationName: locationNames,
    contactPerson: contactNames,
    contactNo: contactNo,
    sortby: sortby,
    cuisine: cuisines,
    option: option,
    search: searchKeyword,
    dietary: dietary,
  };

  let filterData = {
    search: searchKeyword,
    dietary: dietary,
    sortby: sortby,
    cuisine: cuisines,
    option: option,
  };

  const onCuisine = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setCuisines(typeof value === "string" ? value.split(",") : value);
    setPage(1);
  };

  const onPackageOption = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setOption(typeof value === "string" ? value.split(",") : value);
    setPage(1);
  };

  function onSortBy(e: SelectChangeEvent<string>) {
    setSortby(e.target.value);
    setPage(1);
  }

  function onSearch(e: SelectChangeEvent<string>) {
    setSearchKeyword(e.target.value);
    setPage(1);
  }

  function onDietary(e: SelectChangeEvent<string>) {
    setDietary(e.target.value);
    setPage(1);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <div>
      <LoginHeader />
      <CatererListFilterDrawer
        isOpen={openFilter}
        onCloseFilter={onCloseFilter}
        search={searchKeyword}
        dietary={dietary}
        sortBy={sortby}
        packageOption={option}
        cuisine={cuisines}
        cuisineList={cuisinesData?.listCuisineType?.data}
        setCuisine={onCuisine}
        setPackageOption={onPackageOption}
        setSortBy={onSortBy}
        setSearch={onSearch}
        setDietary={onDietary}
        onClear={handleClear}
      />

      <div>
        <div className="listing-filter-wrap">
          <div className="listing-filter-primary">
            <CatererFilter
              isEdit={false}
              headCount={headCount}
              address={address}
              date={startDate}
              coordinates={coordinates}
              zip={zipCode}
              savedAddressId={savedAddressId}
              addressId={addressIds}
              setAddress={setAddress}
              setHeadCount={setHeadCount}
              setDate={setStartDate}
              onLocationOptions={onUpdateLocationOptions}
            />
          </div>
          <CatererListFilter
            count={catererData?.catererSearchList?.count}
            search={searchKeyword}
            dietary={dietary}
            sortBy={sortby}
            packageOption={option}
            cuisine={cuisines}
            cuisineList={cuisinesData?.listCuisineType?.data}
            setCuisine={onCuisine}
            setPackageOption={onPackageOption}
            setSortBy={onSortBy}
            setSearch={onSearch}
            setDietary={onDietary}
            onOpenFilter={onOpenFilter}
            onClear={handleClear}
          />
        </div>
        {catererloading ? (
          <CatererLoader />
        ) : catererData?.catererSearchList?.count === 0 || !isLocationCorrect ? (
          <EmptyCatererListMessage
            isFilterSelected={isFilterUpdate}
            isDateSelected={!!startDate}
            isAddressSelected={!!address}
          />
        ) : (
          <div className="cater-list" ref={parentRef}>
            <div className="container">
              <div className="row">
                {catererData?.catererSearchList?.data?.map((item, index) => (
                  <CatererItem
                    key={index}
                    item={item}
                    filterData={filterData}
                    urlData={urlData}
                    retainFilter={retainFilter}
                  />
                ))}
              </div>
            </div>

            {catererData?.catererSearchList?.count !== 0 && (
              <div className="table-pagenation">
                <Stack>
                  <Pagination
                    className="tableCount m-auto"
                    count={Math.ceil(catererData?.catererSearchList?.count / CATERER_LIST_PAGE_NUM)}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handleChangePage}
                  />
                </Stack>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
