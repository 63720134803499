import { Skeleton } from "@mui/material";
import { deliveryFeeformatter, formatter } from "Modules/Common/commonUtils";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCatererRating } from "@utils/hooks/use-caterer-rating";

interface ICatererItemProps {
  item: any;
  urlData: any;
  filterData: any;
  retainFilter(d: any): void;
}

const CatererImage = ({ imageUrl, onImageLoad, isLoaded, children }) => (
  <div className="cater-list-item-img">
    {!isLoaded && (
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: "60%" }} />
      </Skeleton>
    )}
    <img
      onLoad={onImageLoad}
      style={{ height: !isLoaded && "0" }}
      src={imageUrl}
      className="img-fluid"
      alt="Cater"
    />
    {children}
  </div>
);

const CatererDetails = ({ item, averageRating, totalReviews }) => (
  <div className="cater-list-item-cnt">
    <h4>
      {item?.businessName}
      {averageRating > 0 && (
        <label>
          <img src="../assets/images/star-rating.svg" className="img-fluid" alt="Rating" />
          <span>
            {averageRating} <small>({totalReviews === 5 ? "5+" : totalReviews})</small>
          </span>
        </label>
      )}
    </h4>
    <h6>{item?.tagLine}</h6>
    <div className="cater-list-order">
      <span>
        <img src="../assets/images/icon-dollar-sign.svg" className="img-fluid" alt="Icon Dollar" />
        Min Ord: {formatter.format(item?.minOrderAmt)}
      </span>
      <span>
        <img src="../assets/images/icon-truck.svg" className="img-fluid" alt="Icon Truck" />
        Delivery Fee: {deliveryFeeformatter(item)}
      </span>
      <span>
        <img src="../assets/images/icon-map-pin.svg" className="img-fluid" alt="Icon Map" />
        Est. {item?.distance === 0 ? item?.distance : item?.distance?.toFixed(2)} mi
      </span>
    </div>
  </div>
);

export function CatererItem({ item, urlData, filterData, retainFilter }: ICatererItemProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  const onImageLoad = () => {
    setIsLoaded(true);
  };

  const { averageRating, totalReviews } = useCatererRating({
    googleRating: item?.googleReviewRating,
    totalStarRating: item?.totalRating?.starRate,
    googleReviewCount: item?.googleReviewCount || 0,
    totalReviewCount: item?.totalRating?.count || 0,
    customReviewCount: item?.customReviewCount || 0,
  });

  const imageUrl = item?.imageUrl === "#" ? "../assets/images/home.jpg" : item?.imageUrl || "../assets/images/home.jpg";

  return (
    <div className="col-md-6 col-lg-4 cater-list-item-outer">
      <Link to={`${item?.slug}`} state={urlData}>
        <div className="cater-list-item" onClick={() => retainFilter(filterData)}>
          <CatererImage imageUrl={imageUrl} onImageLoad={onImageLoad} isLoaded={isLoaded}>
            {item?.caterCash && (
              <div className="cater-cash-tag">
                <b>{`${item?.caterCash}%`}</b>
                <label>CaterCash</label>
              </div>
            )}
          </CatererImage>
          <CatererDetails item={item} averageRating={averageRating} totalReviews={totalReviews} />
        </div>
      </Link>
    </div>
  );
}
