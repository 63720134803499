import React, { useState } from "react";
import { formatter } from "../../../Modules/Common/commonUtils";
import { Skeleton } from "@mui/material";
import { ICatererRatingProps, useCatererRating } from "@utils/hooks/use-caterer-rating";

interface ICatererMainInfoProps extends ICatererRatingProps {
  name: string;
  cash: number;
  address: string;
  zip: string;
  phone: string;
  minOrder: number;
  deliveryFee: string;
  image: string;
  googleReviews: any[];
}

const ReviewItem = ({ review }) => (
  <div className="col-md-6 review-item">
    <div className="review-item-header">
      <h6>{review?.userName || "Google Reviewer"}</h6>
      <div className="review-item-rating">
        {[...Array(review?.rating)].map((_, i) => (
          <img key={i} src="../assets/images/Star1.svg" className="img-fluid" alt="Star" />
        ))}
      </div>
    </div>
    <p>{review?.text.length > 250 ? `${review.text.substring(0, 250)}...` : review.text}</p>
  </div>
);

const CatererDetailItem = ({ iconSrc, children }) => (
  <li>
    <span className="dmp-bg">
      <img src={iconSrc} className="img-fluid" alt="Icon" />
    </span>
    {children}
  </li>
);

export function CatererMainInfo({
  name,
  cash,
  address,
  zip,
  phone,
  minOrder,
  deliveryFee,
  image,
  googleReviews,
  googleRating,
  totalStarRating,
  googleReviewCount,
  totalReviewCount,
  customReviewCount,
}: ICatererMainInfoProps) {
  const caterCash = cash || 5;
  const [isLoaded, setIsLoaded] = useState(false);

  function getTopTwoReviews(reviews) {
    if (!reviews || reviews.length === 0) return [];
    return [...reviews].sort((a, b) => b.rating - a.rating).slice(0, 2);
  }

  const { averageRating: avgGoogleReviews, totalReviews } = useCatererRating({
    googleRating,
    totalStarRating,
    googleReviewCount,
    totalReviewCount,
    customReviewCount,
  });

  const topReviews = getTopTwoReviews(googleReviews);

  const onImageLoad = () => setIsLoaded(true);

  return (
    <div className="container">
      <h2 className="h2">{name}</h2>
      <div className="row">
        <div className={topReviews.length > 0 ? "col-md-8" : "col-md"}>
          <ul className="d-flex detail-main-points">
            <CatererDetailItem iconSrc="../assets/images/cashflow.svg">
              <b>{caterCash}%</b> CaterCash
            </CatererDetailItem>
            {avgGoogleReviews > 0 && (
              <CatererDetailItem iconSrc="../assets/images/Star1.svg">
                <b>{avgGoogleReviews.toFixed(1)}</b> ({totalReviews === 5 ? "5+" : totalReviews} Review
                {totalReviews === 1 ? "" : "s"})
              </CatererDetailItem>
            )}
            <CatererDetailItem iconSrc="../assets/images/Icon-Location1.svg">
              {address}, {zip}
            </CatererDetailItem>
            <CatererDetailItem iconSrc="../assets/images/call.svg">{phone}</CatererDetailItem>
            <CatererDetailItem iconSrc="../assets/images/dollar-sign.svg">
              Min Order: {formatter.format(minOrder)}
            </CatererDetailItem>
            <CatererDetailItem iconSrc="../assets/images/truck.svg">
              Delivery Fee: {deliveryFee}
            </CatererDetailItem>
          </ul>

          <p className="lead py-3" style={{ fontWeight: "bold" }}>
            See What Customers Are Saying
          </p>
          <div className="row">
            {topReviews.length > 0 &&
              topReviews.map((review, index) => <ReviewItem key={index} review={review} />)}
          </div>
        </div>
        {topReviews.length > 0 && (
          <div className="col-md">
            <div
              className="cater-list-item-img d-flex justify-content-end"
              style={{ position: "relative" }}>
              {!isLoaded && (
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "60%" }} />
                </Skeleton>
              )}
              <img
                onLoad={onImageLoad}
                style={{ height: !isLoaded ? "0" : "auto", borderRadius: "10px" }}
                src={
                  image === "#" ? "../assets/images/home.jpg" : image || "../assets/images/home.jpg"
                }
                className="img-fluid"
                alt="Cater"
              />
              <div className="cater-cash-tag">
                <b>{`${caterCash}%`}</b>
                <label>CaterCash</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
