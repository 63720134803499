// this page is for creating the caterers initial credentials
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import {
  IconButton,
  Button,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { authVar, userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client/react/hooks";
import { CREATE_CATERER, CREATE_CUSTOMER, UPDATE_CATERE } from "../../Graphql/mutations";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";
import { useAppSelector } from "@services/redux";

export default function CreateAccount(props) {
  const navigate = useNavigate();
  let { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const order = useAppSelector((state) => state.order.draft);
  const [cookies, setCookie] = useCookies(["user", "image", "userType"]);
  const userType = props.userType?.user;

  // validation
  const schema = yup.object().shape({
    lastName: yup.string().required("Last Name is required"),
    firstName: yup.string().required("First Name is required"),
    email: yup.string().required("Email is required").email("Please enter a valid email address"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(14, "The phone number must be 10 digits."),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Password and confirm password do not match"),
    backupContact: yup.string().min(14, "The phone number must be 10 digits."),
    referralCode: yup.string().optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const smsEnabledValue = watch("smsEnabled");

  const [createCaterer, { loading }] = useMutation(CREATE_CATERER, {
    onCompleted: (res) => {
      setCookie("user", res?.createCaterer?.fullName, {
        path: "/",
        domain: ".caterplace.com",
      });
      setCookie("image", res?.createCaterer?.imageUrl, {
        path: "/",
        domain: ".caterplace.com",
      });
      setCookie("userType", res?.createCaterer?.userType, {
        path: "/",
        domain: ".caterplace.com",
      });
      props.setActiveStep(1); //incrementing stepper
    },
  });
  function getUuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  }
  const [uuId, setUuid] = useState(getUuid());
  const [updateCaterer] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(5);
    },
  });
  //after customer registration the user should have to redirect to home screen
  const [createUserIdentity, { loading: creatoeLoading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (res) => {
      setCookie("userType", res?.createUserIdentity?.userType, {
        path: "/",
        domain: ".caterplace.com",
      });

      if (props?.userType?.userType?.faqReferFriend === "faqReferFriend") {
        navigate("/referFriend");
      } else {
        props?.setActiveStep(1);
      }
    },
  });

  // toggle between text/password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // Helper functions
  const handleRegistrationSuccess = (res, userType) => {
    const responseData =
      userType === "Customer" ? res.data?.createUserIdentity : res.data?.createCaterer;

    const token = responseData?.token;
    if (token) {
      localStorage.setItem("@authToken", token);
      userDeatils({ data: res.data });
      authVar({ loaded: true, auth: true });

      toggleSnackbar({
        status: true,
        message: "You have been successfully registered",
        variant: "success",
      });
    }
  };

  const handleRegistrationError = (err) => {
    const message = err?.message || "An error occurred";
    toggleSnackbar({
      status: true,
      message: message,
      variant: "error",
    });
  };

  const prepareCatererData = (params) => {
    const paramsClone = structuredClone(params);
    delete paramsClone.confirmPassword;

    return {
      ...paramsClone,
      role: "Admin",
      name: `${paramsClone.firstName} ${paramsClone.lastName}`,
      userType: "Caterer",
      tabValue: "0",
      adminFlg: false,
      userSrc: "Registration",
    };
  };

  const prepareCustomerData = (params, order) => {
    const paramsClone = structuredClone(params);
    delete paramsClone.confirmPassword;

    return {
      ...paramsClone,
      firstName: paramsClone.firstName || "",
      lastName: paramsClone.lastName || "",
      userSrc: "",
      userType: "Customer",
      name: `${paramsClone.firstName} ${paramsClone.lastName}`,
      regFlag: null,
      role: null,
      uuid: order.uuid,
      referralCode: paramsClone.referralCode || "",
    };
  };

  async function onSubmit(params) {
    if (!checked) {
      setErrorMessage("Please accept to the terms and conditions");
      return;
    }

    setSubmitting(true);

    try {
      if (userType !== "Customer") {
        const catererData = prepareCatererData(params);
        const response = await createCaterer({
          variables: { data: catererData },
        });
        handleRegistrationSuccess(response, "Caterer");
        props.setActiveStep(1);
      } else {
        const customerData = prepareCustomerData(params, order);
        const response = await createUserIdentity({
          variables: { data: customerData },
        });
        handleRegistrationSuccess(response, "Customer");

        if (props?.userType?.userType?.faqReferFriend === "faqReferFriend") {
          navigate("/referFriend");
        } else {
          props?.setActiveStep(1);
        }
      }
    } catch (err) {
      handleRegistrationError(err);
    } finally {
      setSubmitting(false);
    }
  }

  // setting the phone number format & validations
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6) + "-" + newNum.slice(6, 10);
    }
    return x;
  };

  return (
    <div className="create-step-form">
      {userType === "Customer" ? (
        <h5 className="h5">Customer Account Details</h5>
      ) : (
        <h5 className="h5">Caterer Account Details</h5>
      )}
      {userType === "Customer" ? (
        <br />
      ) : (
        <h6 className="subhead form-space">
          We just need a few details to get you up and running.
        </h6>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col-md-6 textBox">
            <TextField
              id="createCustomerFirstName"
              label="FIRST NAME*"
              autoFocus
              variant="outlined"
              className="textField allFeild"
              {...register("firstName")}
            />
            {errors.firstName && <span className="error-msg">{errors.firstName.message}</span>}
          </div>

          <div className="col-md-6 textBox">
            <TextField
              id="createCustomerLastName"
              label="LAST NAME*"
              variant="outlined"
              className="textField allFeild"
              {...register("lastName")}
            />
            {errors.lastName && <span className="error-msg">{errors.lastName.message}</span>}
          </div>

          <div className={"col-md-6 textBox"}>
            <TextField
              id="createAccountLoginEmail"
              label="EMAIL*"
              variant="outlined"
              className="textField allFeild"
              {...register("email")}
            />
            {errors.email && <span className="error-msg">{errors.email.message}</span>}
          </div>
          <div className="col-md-6 textBox">
            <TextField
              id="createAccountPhoneNumber"
              label={userType === "Customer" ? "PHONE*" : "BUSINESS CONTACT NUMBER*"}
              variant="outlined"
              autoComplete="new-Phone"
              InputProps={{
                autoComplete: "new-username",
              }}
              className={`textField allFeild ${userType === "Customer" ? "" : "mb-0"}`}
              // {...register("phoneNumber")}
              value={phoneNumber}
              onChange={(e) => {
                if (
                  !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                  e.target.value.replace(/[-,(,), ]+/g, "").length <= 10
                ) {
                  register("phoneNumber").onChange({
                    target: { value: e.target.value, name: "phoneNumber" },
                  });
                  setPhoneNumber(phoneNumberFormat(e.target.value));
                }
              }}
            />
            {!errors.phoneNumber && userType !== "Customer" && (
              <FormHelperText className="mt-0">
                We will only use your number for order updates.
              </FormHelperText>
            )}
            {errors.phoneNumber && <span className="error-msg">{errors.phoneNumber.message}</span>}
          </div>

          <div className="col-md-6 textBox password-feild">
            <TextField
              id="createAccountPassword"
              label="PASSWORD*"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              className="textField allFeild"
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors.password && <span className="error-msg">{errors.password.message}</span>}
          </div>
          <div className="col-md-6 textBox">
            <TextField
              id="filled-basic"
              label="CONFIRM PASSWORD*"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              className="textField allFeild"
              {...register("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.confirmPassword && (
              <span className="error-msg">{errors.confirmPassword.message}</span>
            )}
          </div>

          {userType !== "Customer" && (
            <div className="col-md-12 d-flex justify-content-end">
              <FormControlLabel
                className="mt-2"
                control={<Checkbox {...register("smsEnabled")} checked={!!smsEnabledValue} />}
                label="I would like to receive SMS notifications for incoming orders and updates."
              />
            </div>
          )}

          {userType === "Customer" && (
            <div className="col-md-12">
              <TextField
                id="createCustomerReferralCode"
                label="REFERRAL CODE (IF APPLICABLE)"
                variant="outlined"
                className="textField allFeild"
                {...register("referralCode")}
              />
            </div>
          )}

          <div className="col-12">
            <div className="createStepBtn">
              <Link to="/login">
                <Button className="cancelBtn" variant="contained" color="secondary">
                  Cancel
                </Button>
              </Link>
              <Button
                className="saveBtn"
                variant="contained"
                type="submit"
                color="primary"
                disabled={submitting}>
                {loading || creatoeLoading || submitting ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Create An Account"
                )}
                <ArrowForwardIcon className="btnArrow" />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
