import { useMemo } from 'react';

export interface ICatererRatingProps {
  googleRating: number;
  totalStarRating: number;
  googleReviewCount: number;
  totalReviewCount: number;
  customReviewCount: number;
}

export const useCatererRating = ({
  googleRating,
  totalStarRating,
  googleReviewCount,
  totalReviewCount,
  customReviewCount,
}: ICatererRatingProps) => {
  return useMemo(() => {
    const totalReviews = googleReviewCount + totalReviewCount + customReviewCount;

    const hasGoogleRating = googleRating > 0;
    const hasTotalStarRating = totalStarRating > 0;
    const totalWeight = (hasGoogleRating ? 1 : 0) + (hasTotalStarRating ? 1 : 0);

    const averageRating = totalWeight > 0 ? (googleRating + totalStarRating) / totalWeight : 0;

    return { averageRating: +averageRating.toFixed(1), totalReviews };
  }, [googleRating, totalStarRating, googleReviewCount, totalReviewCount, customReviewCount]);
};
