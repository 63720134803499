import React, { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import autosuggestParse from "autosuggest-highlight/parse";
import autosuggestMatch from "autosuggest-highlight/match";

import { useDebounceValue } from "usehooks-ts";

import {
  Autocomplete,
  Box,
  Grid,
  OutlinedTextFieldProps,
  TextField,
  Typography,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";

import { GOOGLE_API_KEY } from "../../constants/map.constants";
import { IAddressListItemResponse, ISearchAddressResponse } from "typings/address.api";

export interface ISearchLocationPayload {
  city: string;
  zip: string;
  coordinates: any;
  street: string; // TODO remove (only for payment now)
  state: string;
}

export interface ILocationInputProps extends Omit<OutlinedTextFieldProps, "variant" | "onChange"> {
  value: any; // TODO: create correct type
  noOptionsText?: string;
  onChangeLocationOptions?(payload: ISearchLocationPayload): void;
  onChange(v: string): void;
  fetchSavedAddress?(v: string): Promise<{ data: ISearchAddressResponse }>;
  onSelectSavedAddress?(v: IAddressListItemResponse): void;
}

interface ILocationOption {
  name: string;
  description: string;
  placeId?: string;
  addressId?: string;
  addressData?: IAddressListItemResponse;
}

export function LocationInput({
  id,
  label,
  value,
  onChange,
  onBlur,
  noOptionsText,
  className,
  onChangeLocationOptions,
  helperText,
  inputProps,
  fetchSavedAddress,
  onSelectSavedAddress,
  ...props
}: ILocationInputProps) {
  const [query, setQuery] = useDebounceValue("", 300);
  const [options, setOptions] = useState<ILocationOption[]>([]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const handleChange = (event, input: string) => {
    setQuery(input);
  };

  const handleSelect = (event, selectedLocation: ILocationOption) => {
    if (!selectedLocation) {
      fetchPlace(null);
      onChange(null);
      return;
    }

    if (selectedLocation.addressId && selectedLocation.addressData) {
      if (onSelectSavedAddress) {
        return onSelectSavedAddress(selectedLocation.addressData);
      }
      onChange(selectedLocation.name);
      return onChangeLocationOptions({
        city: selectedLocation.addressData.city,
        zip: selectedLocation.addressData.customerZip,
        coordinates: [
          selectedLocation.addressData.latitude,
          selectedLocation.addressData.longitude,
        ],
        state: selectedLocation.addressData.customerState,
        street: selectedLocation.addressData.street,
      });
    }
    fetchPlace(selectedLocation.placeId);
    onChange(selectedLocation.name);
  };

  useEffect(() => {
    if (!query) {
      return setOptions([]);
    }
    fetchOptions(query);
  }, [query]);

  const fetchPlace = async (placeId) => {
    if (!isLoaded || loadError || !onChangeLocationOptions) return;

    if (!placeId) {
      return onChangeLocationOptions({
        city: "",
        zip: "",
        coordinates: [],
        state: "",
        street: "",
      });
    }

    const service = new window.google.maps.places.PlacesService(document.createElement("div"));

    service.getDetails({ placeId }, (place, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      const streetNumberObject = place.address_components.find((component) =>
        component.types.includes("street_number"),
      );

      const streetNameObject = place.address_components.find((component) =>
        component.types.includes("route"),
      );

      const street =
        streetNameObject && streetNumberObject
          ? `${streetNumberObject.short_name} ${streetNameObject.short_name}`
          : "";

      const cityObject = place.address_components.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("administrative_area_level_1"),
      );

      const stateObject = place.address_components.find(
        (component) =>
          component.types.includes("political") &&
          component.types.includes("administrative_area_level_1"),
      );

      const state = stateObject ? stateObject.short_name : "";

      const city = cityObject && cityObject.long_name ? cityObject.long_name : "";

      const zipCodeObject = place.address_components?.find((component) =>
        component.types.includes("postal_code"),
      );
      const zip = zipCodeObject && zipCodeObject.long_name ? zipCodeObject.long_name : "";

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      onChangeLocationOptions({
        city,
        zip,
        coordinates: [lng, lat],
        street,
        state,
      });
    });
  };

  const fetchOptions = async (input) => {
    if (!input || !isLoaded || loadError) return [];

    const service = new window.google.maps.places.AutocompleteService();

    const request = {
      input,
      types: ["address"],
      componentRestrictions: { country: "us" },
    };

    const googleAddresses = await service.getPlacePredictions(request);
    console.log(googleAddresses, request);
    const addressOptions: ILocationOption[] = googleAddresses.predictions?.map((item) => ({
      name: item.description,
      description: item.structured_formatting ? item.structured_formatting.secondary_text : "",
      placeId: item.place_id,
    }));
    let savedAddressOptions: ILocationOption[] = [];
    if (fetchSavedAddress) {
      try {
        const savedAddressesResponse = await fetchSavedAddress(input);
        if (savedAddressesResponse) {
          savedAddressOptions = savedAddressesResponse.data.searchAddress.map((item) => ({
            name: item.locationName,
            description: item.street,
            placeId: null,
            addressId: item._id,
            addressData: item,
          }));
        }
      } catch (e) {
        console.warn(e.message);
      }
    }
    setOptions(savedAddressOptions.concat(addressOptions));
  };

  return (
    <Autocomplete
      {...(props as any)}
      id={id}
      autoComplete
      includeInputInList
      openOnFocus
      noOptionsText={noOptionsText || "No addresses"}
      className={className}
      onChange={handleSelect}
      onInputChange={handleChange}
      value={value && value.description ? value.description : value}
      filterOptions={(options) => {
        return options;
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            {...inputProps}
            InputProps={{
              ...(params.InputProps || {}),
              ...((inputProps && inputProps.InputProps) || {}),
            }}
            label={label}
            fullWidth
            variant="outlined"
            error={helperText ? !!helperText : false}
            helperText={helperText ? helperText : ""}
            onBlur={onBlur}
          />
        );
      }}
      options={options}
      getOptionLabel={(option: any) => {
        return option ? option.name || option.description || option : "";
      }}
      isOptionEqualToValue={(option: any, input) => {
        return option && option.name === input;
      }}
      renderOption={({ id, ...props }, option: ILocationOption) => {
        if (!option) {
          return null;
        }
        const highlightedName = autosuggestParse(option.name, autosuggestMatch(option.name, query));
        return (
          <li {...props} key={id}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOn} sx={{ color: "text.secondary", mr: 2 }} />
              </Grid>
              <Grid item xs>
                {option.addressId ? (
                  <p style={{ fontWeight: "bold" }}>{option.name}</p>
                ) : (
                  highlightedName.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}>
                      {part.text}
                    </span>
                  ))
                )}
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
